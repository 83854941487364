import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { GroupDataAccessService } from '@joorney/users-shared-frontend-group-data-access';
import { userActions } from '@joorney/users-shared-frontend-user-store';
import { UserGroup } from '@joorney/users-shared-user-group-domain';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { map, tap } from 'rxjs';
import { NO_ACCESS_ROUTE, NO_AUTHORIZATION_ROUTE } from '../routing/authentication-routing.constant';

export const canAccessGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);
  return inject(GroupDataAccessService)
    .findMine()
    .pipe(
      map((groups) => groups.map((g) => g.name as UserGroup)),
      tap((groups) => store.dispatch(userActions.setCurrentGroups({ groups }))),
      map((userGroups) => {
        if (_.isEmpty(userGroups)) {
          console.log('canAccessGuard - no access');
          return router.createUrlTree([NO_ACCESS_ROUTE]);
        }
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const routeGroups = route.data?.['userGroups'] as UserGroup[];
        if (_.isEmpty(routeGroups)) {
          return true;
        }
        if (_.chain(routeGroups).intersection(userGroups).isEmpty().value()) {
          console.log('canAccessGuard - no authorization');
          return router.createUrlTree([NO_AUTHORIZATION_ROUTE]);
        }
        return true;
      }),
    );
};
